var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"custom__modal--large",attrs:{"id":"modal-sku","hide-header":"","hide-footer":"","ok-only":"","ok-title":"Accept","centered":"","size":"lg","title":"Large Modal"}},[_c('div',{staticClass:"section"},[_c('b-container',{staticClass:"mt-2"},[_c('b-row',{staticClass:"justify-content-center h-100 bg-white"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-center text-dark"},[_vm._v("Upload SKU")]),_c('hr')]),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-toko"}},[_vm._v("Toko")]),_c('b-form-group',{staticClass:"w-100"},[_c('v-select',{attrs:{"label":"name","options":_vm.optionsToko,"placeholder":"Toko"},on:{"search":_vm.onSearchToko},model:{value:(_vm.formPayload.merchant_uuid),callback:function ($$v) {_vm.$set(_vm.formPayload, "merchant_uuid", $$v)},expression:"formPayload.merchant_uuid"}}),(
                    _vm.messages.merchant_uuid &&
                    Array.isArray(_vm.messages.merchant_uuid) &&
                    _vm.messages.merchant_uuid.length > 0
                  )?_c('small',{staticClass:"text-error fw-bold-500 size12"},[_vm._v(_vm._s(_vm.messages.merchant_uuid.join(", ")))]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-1 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-file"}},[_vm._v("Upload File")]),_c('b-form-group',{staticClass:"w-100"},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".csv"},on:{"change":function($event){return _vm.inputFile($event)}}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.result.data)?_c('vue-good-table',{attrs:{"max-height":"30vh","columns":_vm.columns,"rows":_vm.result.data,"fixed-header":false,"sort-options":{
              enabled: false,
            },"pagination-options":{
              enabled: false,
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(props.row.status))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,4197357040)}):_vm._e(),(_vm.result.data)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap bg-white border",staticStyle:{"padding":"0.8rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '15', '20']},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Tampilkan 1 ke "+_vm._s(_vm.filter.per_page)+" dari "+_vm._s(_vm.result.total)+" transaksi. ")])],1),_c('div',[_c('b-row',{staticClass:"mx-0 align-items-center"},[_c('b-col',[_c('pagination',{staticClass:"mb-0",attrs:{"data":_vm.result,"limit":4,"align":'right'},on:{"pagination-change-page":_vm.getHistory}})],1)],1)],1)]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"button__group"},[_c('b-button',{staticClass:"button__cancel",on:{"click":function($event){return _vm.$bvModal.hide('modal-sku')}}},[_vm._v(" Batal ")]),_c('b-button',{staticClass:"button__save d-flex align-items-center justify-content-center",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.postData}},[(_vm.isLoading)?_c('div',[_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1):_vm._e(),_vm._v(" Simpan ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }