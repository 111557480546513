<template>
  <b-modal
    id="modal-sku"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <b-container class="mt-2">
        <b-row class="justify-content-center h-100 bg-white">
          <b-col cols="12">
            <h5 class="text-center text-dark">Upload SKU</h5>
            <hr />
          </b-col>
          <b-col cols="12" md="12">
            <b-row>
              <!-- Toko -->
              <b-col cols="12" md="12" class="mb-1 custom__form--input">
                <label for="v-toko">Toko</label>
                <b-form-group class="w-100">
                  <v-select
                    v-model="formPayload.merchant_uuid"
                    label="name"
                    :options="optionsToko"
                    placeholder="Toko"
                    @search="onSearchToko"
                  />
                  <small
                    v-if="
                      messages.merchant_uuid &&
                      Array.isArray(messages.merchant_uuid) &&
                      messages.merchant_uuid.length > 0
                    "
                    class="text-error fw-bold-500 size12"
                    >{{ messages.merchant_uuid.join(", ") }}</small
                  >
                </b-form-group>
              </b-col>
              <!-- Toko -->
              <b-col cols="12" md="12" class="mb-1 custom__form--input">
                <label for="v-file">Upload File</label>
                <b-form-group class="w-100">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".csv"
                    @change="inputFile($event)"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <vue-good-table
              v-if="result.data"
              max-height="30vh"
              :columns="columns"
              :rows="result.data"
              :fixed-header="false"
              :sort-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: false,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'status'">
                  <span class="text-capitalize">{{ props.row.status }}</span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div
              v-if="result.data"
              class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
              style="padding: 0.8rem"
            >
              <div class="d-flex align-items-center">
                <b-form-select
                  v-model="filter.per_page"
                  :options="['10', '15', '20']"
                  class="mx-1"
                />
                <span class="text-nowrap">
                  Tampilkan 1 ke {{ filter.per_page }} dari
                  {{ result.total }} transaksi.
                </span>
              </div>
              <div>
                <b-row class="mx-0 align-items-center">
                  <b-col>
                    <pagination
                      class="mb-0"
                      :data="result"
                      :limit="4"
                      :align="'right'"
                      @pagination-change-page="getHistory"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="button__group">
      <b-button class="button__cancel" @click="$bvModal.hide('modal-sku')">
        Batal
      </b-button>
      <b-button
        class="button__save d-flex align-items-center justify-content-center"
        :disabled="isLoading"
        @click="postData"
      >
        <div v-if="isLoading">
          <b-spinner variant="primary" small />
        </div>
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { VueGoodTable } from "vue-good-table";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BContainer,
    BSpinner,
    VueGoodTable,
    BFormSelect,
    BFormFile,
  },
  computed: {
    ...mapState({
      histories: (state) => state.produk.histories,
    }),
  },
  props: {
    formUpload: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    cleanUpForm: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      formPayload: {
        merchant_uuid: "",
        file: "",
      },
      messages: "",
      photo_url: "",
      editUuid: null,
      optionsSatuan: [],
      isLoading: false,
      optionsToko: [],
      result: {},
      currentPage: 1,
      file_url: null,
      columns: [
        {
          label: "Judul",
          field: "title",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      filter: {
        // search: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  watch: {
    formUpload(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayloadUpload", value);
      },
      deep: true,
    },
    filter: {
      handler: _.debounce(function () {
        this.getDataHistory();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getDataToko();
    this.getDataHistory();
  },
  methods: {
    ...mapActions({
      getDataTokoAction: "toko/getData",
      postDataFile: "produk/postDataFile",
    }),
    ...mapActions("produk", ["getHistory"]),

    async postData() {
      this.isLoading = true;
      const form = this.preparePayload();

      try {
        const response = await this.postDataFile({
          id: "",
          payload: form,
        });
        if (response) {
          this.$bvModal.hide("modal-sku");
          this.getDataHistory();
          successNotification(this, "Success", "SKU berhasil di upload");
          this.formPayload = {
            merchant_uuid: "",
            file: null,
          };
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },

    async getDataHistory(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      await this.getHistory({
        params: queryParams,
      });
      this.result = this.histories;
      this.currentPage = this.histories.current_page;
      this.isLoading = false;
    },

    onSearchToko(search, loading) {
      if (search.length) {
        loading(true);
        this.searchToko(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsToko = [];
          this.getDataToko();
          loading(false);
        }, 500);
      }
    },
    searchToko: _.debounce((loading, search, vm) => {
      vm.getDataTokoAction({
        params: {
          search,
        },
      }).then((response) => {
        vm.optionsToko = response.data;
        loading(false);
      });
    }, 300),
    async getDataToko() {
      const response = await this.getDataTokoAction({
        params: "",
      });
      this.optionsToko = response.data;
    },

    inputFile(event) {
      this.formPayload.file = event.target.files[0];
      this.showFile(event.target.files[0]);
    },
    showFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.file_url = e.target.result;
        // this.file_name = file.name;
        // this.file_size = file.size;
        // var str = e.target.result;
        // var startIndex = str.indexOf("/") + 1;
        // var endIndex = str.indexOf(";");
        // var imageType = str.substring(startIndex, endIndex);
        // this.file_type = imageType;
      };
      reader.readAsDataURL(file);
    },

    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key].uuid || this.formPayload[key]);
        }
      }
      return form;
    },

    directPage() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-sku");
    },
  },
};
</script>

<style lang="scss" scoped>
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
  .vgt-table.bordered {
    thead th:last-child {
      text-align: left !important;
    }
  }
}

#modal-sku {
  .modal-content {
    border-radius: 16px;

    .modal-body {
      padding: 0 !important;
    }
  }
}

.button__group {
  display: flex;
  width: 100%;

  .button__cancel {
    background-color: #ffffff !important;
    border-color: #fff !important;
    color: $dark !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 0px 16px;
    width: 100%;
    height: 52px;
    font-size: 14px;
    font-weight: 800;
  }

  .button__save {
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    height: 52px;
    font-size: 14px;
    font-weight: 800;
  }
}
</style>
